
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faSignOutAlt, faTimes, faCity, faUsers, faShieldAlt, faImages, faBriefcase, faSchool, faGraduationCap, faChalkboard, faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import AuthUser from "./AuthUser";
import YbpLogo from "../assets/img/ybp-logo.png";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);
  const {user} = AuthUser();

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    let navItemClassName = "";
    if (link === Routes.PhotoUploads.path.slice(0, 14)) {
      navItemClassName = pathname.includes(link) ? "active" : "";
    } else {
      navItemClassName = link === pathname ? "active" : "";
    }
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-3 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ReactHero} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <Image src={YbpLogo} className="ybp-logo" />
              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
              {             
                user.features.city_management && <NavItem title="City Management" icon={faCity} link={Routes.Cities.path} />
              }
              {             
                user.features.institute_management && <NavItem title="Institution Management" icon={faSchool} link={Routes.Institutes.path} />
              }
              {             
                user.features.class_management && <NavItem title="Class Management" icon={faChalkboard} link={Routes.Cls.path} />
              }
              {             
                user.features.section_management && <NavItem title="Section Management" icon={faChalkboardTeacher} link={Routes.Sections.path} />
              }
              {             
                user.features.student_management && <NavItem title="Student Management" icon={faGraduationCap} link={Routes.Students.path} />
              }
              {             
                user.features.staff_management && <NavItem title="Staff Management" icon={faBriefcase} link={Routes.Staffs.path} />
              }
              {             
                user.features.photo_uploads && <NavItem title="Photo Uploads" icon={faImages} link={Routes.PhotoUploads.path.slice(0, 14)} />
              }
              {             
                user.features.role_management && <NavItem title="Role Management" icon={faShieldAlt} link={Routes.Roles.path} />
              }
              {             
                user.features.ybp_staff_management && <NavItem title="YBP Staff Management" icon={faUsers} link={Routes.ybpStaffs.path} />
              }
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
