import { useHistory } from 'react-router-dom';
import { Routes } from "../routes";
import AuthUser from '../components/AuthUser';

const Home = () => {
    const { getToken } = AuthUser();
    const history = useHistory();
    if(!getToken()){
        history.push(Routes.Signin.path);
    } else {
        history.push(Routes.DashboardOverview.path);
    }
    return null;
}
export default Home;