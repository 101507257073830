
import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, FormCheck, Container, InputGroup, Image } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import AuthUser from '../components/AuthUser';
import { Apis } from "../common/Apis";

import { Routes } from "../routes";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import BgImage from "../assets/img/illustrations/signin.svg";
import YbpLogo from "../assets/img/ybp-logo.png";

export default () => {
  const {http, saveToken, getToken} = AuthUser();
  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  if(getToken()){
    history.push(Routes.DashboardOverview.path);
  }

  const handleLogin = (e) => {
      e.preventDefault();
      setError(null);
      setIsPending(true);
      // api call
      http.post(Apis.login, {mobile: mobile, password: password})
      .then(res => {
          setIsPending(false);
          saveToken(res.data.data.admin, res.data.data.access_token, rememberMe);
      })
      .catch(err => {
          setIsPending(false);
          if (err.response.status === 400) {
            const errorMsg = parseError(err.response.data.error);          
            setError(errorMsg);
          } else {
            setError([err.response.data.message]);
          }          
      });
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <div className="text-center">
              <Image src={YbpLogo} height={35} />
          </div>          
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">              
              <div className="shadow-soft p-4 p-lg-5 w-100 fmxw-500">
                {
                    error && <ErrorMsg errors={error} />
                }
                <div className="mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in</h3>
                </div>
                <Form className="mt-4" onSubmit={ handleLogin }>
                  <Form.Group id="mobile" className="mb-4">
                    <Form.Label>Your Mobile</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faMobile} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required placeholder="XXX-XXXX-XXX" 
                      onChange={e => setMobile(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" 
                        onChange={e => setPassword(e.target.value)} />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" checked={rememberMe} onChange={(e)=>setRememberMe(e.target.checked)}/>
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                    </div>
                  </Form.Group>
                  <button type="submit" className="btn cust-button w-100" disabled={isPending}>
                    Sign in{isPending && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                  </button>
                </Form>                                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
