import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Table from "../Table";
import { Form, Button, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faImages, faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../components/AuthUser";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";

const Cls = () => {
  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }  
  const columns = useMemo(
    () => [
      {
        Header: "City",
        accessor: "city_name",
        isSorted: true
      },
      {
        Header: "Institute",
        accessor: "institute_name",
        isSorted: true
      },
      {
        Header: "Branch",
        accessor: "branch_name"
      },
      {
        Header: "Class",
        accessor: "name",
        isSorted: true
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer', marginRight: "10px" }} onClick={ () => showEditForm(original) }><FontAwesomeIcon icon={ faPencilAlt } /></span>
            <span style={{ cursor: 'pointer' }} onClick={ () => deleteClass(original.id) }><FontAwesomeIcon icon={ faTrashAlt } /></span>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Composite Albums" onClick={ () => goToPhotoUploads(original.city_id, original.institute_id, original.id, "CompositePhoto") }><FontAwesomeIcon icon={ faImages } /></button>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Memory Books" onClick={ () => goToPhotoUploads(original.city_id, original.institute_id, original.id, "MemoryBook") }><FontAwesomeIcon icon={ faBook } /></button>            
          </>            
        )
      }
    ],
    []
  );

  const history = useHistory();
  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showDefault, setShowDefault] = useState(false);
  const [cities, setCities] = useState(null);
  const [instByCity, setInstByCity] = useState(null);
  const [classId, setClassId] = useState(null);
  const [className, setClassName] = useState("");
  const [classCity, setClassCity] = useState("");
  const [classInst, setClassInst] = useState("");
  const [classAddLoading, setClassAddLoading] = useState(false);
  const [classAddSuccess, setClassAddSuccess] = useState(null);
  const [classAddError, setClassAddError] = useState(null);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [classEditLoading, setClassEditLoading] = useState(false);
  const [classEditSuccess, setClassEditSuccess] = useState(null);
  const [classEditError, setClassEditError] = useState(null);
  const [classDeleteLoading, setClassDeleteLoading] = useState(false);
  const [classDeleteError, setClassDeleteError] = useState(null);

  const handleClose = () => {
    setClassAddError(null);
    setShowDefault(false);
  }
  const showAddForm = () => {
    setShowDefault(true);
  }

  const saveClass = (e) => {
    e.preventDefault();
    setClassAddError(null);
    setClassAddLoading(true);    
    // api call
    http.post(Apis.cls.save, {name: className, inst_uuid: classInst})
    .then(res => {
        setClassAddLoading(false);
        setClassAddSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setClassAddLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setClassAddError(errorMsg);
        } else {
          setClassAddError([err.response.data.message]);
        }
    });
  }

  const handleEditClose = () => {
    setClassEditError(null);
    setClassId(null);
    setClassName("");
    setClassInst("");
    setShowEditDefault(false);
  }

  const showEditForm = (data) => {
    setClassId(data.id);    
    setClassName(data.name);
    setClassInst(data.institute_id);
    setClassCity(data.city_id);
    getInst(data.city_id);
    setShowEditDefault(true);
  }

  const updateClass = (e) => {
    e.preventDefault();
    setClassEditError(null);
    setClassEditLoading(true);    
    // api call
    http.post(Apis.cls.update, {cls_uuid: classId, name: className, inst_uuid: classInst})
    .then(res => {
        setClassEditLoading(false);
        setClassEditSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setClassEditLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setClassEditError(errorMsg);
        } else {
          setClassEditError([err.response.data.message]);
        }
    });
  }

  const deleteClass = (classId) => {
    const resp = window.confirm("Do you want to delete this class?");
    if (resp) {
      setClassDeleteError(null);
      setClassDeleteLoading(true);
      http.post(Apis.cls.delete, {cls_uuid: classId})
      .then(res => {
        setClassDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setClassDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setClassDeleteError(errorMsg);
        } else {
          setClassDeleteError([err.response.data.message]);
        }
      });
    }
  }

  const goToPhotoUploads = (cityId, instId, classId, productType) => {
    history.push(`${Routes.PhotoUploads.path.slice(0, 14)}/${productType}/${cityId}/${instId}/${classId}`);
  }

  useEffect(() => {
    // api call
    http.get(Apis.cities)
    .then(res => {        
        setCities(res.data.data);
    });
  }, []);

  const getInst = (cityId) => {
    // api call
    http.get(`${Apis.inst_by_city}/${cityId}`)
    .then(res => {        
        setInstByCity(res.data.data);
    });
  }

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.cls.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText) {
      apiString += `&search_keyword=${searchText}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        setData(res.data.data.data);
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 mt-3">
            <h5>Class Management</h5>
          </div>
          <div className="col-sm-6 text-right mt-2">
            <button className="btn cust-button me-2" onClick={showAddForm}>+</button>
          </div>
        </div>
        {
            error && <ErrorMsg errors={error} />
        }
        {
            classDeleteError && <ErrorMsg errors={classDeleteError} />
        }
        {
            classDeleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Add Class</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {
              classAddSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {classAddSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              classAddError && <ErrorMsg errors={classAddError} />
          }
          <Form className="mt-4" onSubmit={ saveClass }>

            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" onChange={(e) => setClassName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select onChange={(e) => getInst(e.target.value)}>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Institute<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select onChange={(e) => setClassInst(e.target.value)}>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                  return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                })
                }
              </Form.Select>
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={classAddLoading}>
                Save{classAddLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showEditDefault} onHide={handleEditClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Edit Class</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleEditClose} />
        </Modal.Header>
        <Modal.Body>
          {
              classEditSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {classEditSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              classEditError && <ErrorMsg errors={classEditError} />
          }
          <Form className="mt-4" onSubmit={ updateClass }>

            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" value={className} onChange={(e) => setClassName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select value={classCity} disabled>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Institute</Form.Label>
              <Form.Select value={classInst} disabled>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                              })
                }
              </Form.Select>
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={classEditLoading}>
                Update{classEditLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>      
    </>
  );
}
export default Cls;