import React from "react";

const ErrorMsg = ({errors, failedRows = null}) => {
    return (
            <div className="row mt-3">
                <div className="col">
                    <div className="alert alert-danger" style={{padding: '.5rem 1rem'}}>
                        <strong>Error!</strong>
                        {
                            failedRows && <p>Un-inserted rows - {failedRows}</p>
                        }
                        <ul>
                        {
                            errors.map((msg, i) => {
                                return <li key={i}>{msg}</li>;
                            })
                        }
                        </ul>
                    </div>
                </div>
            </div>
        );
}
export default ErrorMsg;