
export const Apis = {
    login: "/admin_login",
    get_academic_years: "/get_academic_years",
    cities: "/get_cities_admin",
    inst_by_city: "/get_insts_admin",
    cls_by_inst: "/get_classes_admin",
    section_by_cls: "/get_sections_admin",
    get_students_admin: "/get_students_admin",
    upload_file: "/upload_file_admin",
    bulk_upload: "/bulk_upload",
    add_promotion_images: "/add_promotion_images",
    delete_promotion_images: "/delete_promotion_images",
    replace_promotion_image: "/replace_promotion_image",
    get_features: "/get_features",
    get_roles: "/get_roles",
    change_password: "/change_password",
    city: {
        list: "/get_city_list",
        save: "/add_city",
        update: "/update_city",
        toggleStatus: "/toggle_city"
    },
    institute: {
        list: "/get_inst_list",
        save: "/add_inst",
        update: "/update_inst",
        toggleStatus: "/toggle_inst"
    },
    cls: {
        list: "/get_cls_list",
        save: "/add_cls",
        update: "/update_cls",
        delete: "/delete_cls"
    },
    section: {
        list: "/get_section_list",
        save: "/add_section",
        get_section_classes: "/get_section_classes",
        update: "/update_section",
        delete: "/delete_section"
    },
    student: {
        list: "/get_student_list",
        save: "/add_student",
        update: "/update_student",
        toggleStatus: "/toggle_student"
    },
    staff: {
        list: "/get_staff_list",
        save: "/add_staff",
        update: "/update_staff",
        toggleStatus: "/toggle_staff"
    },
    role: {
        list: "/get_role_list",
        save: "/add_role",
        update: "/update_role",
        delete: "/delete_role"
    },
    user: {
        list: "/get_user_list",
        save: "/add_user",
        update: "/update_user",
        toggleStatus: "/toggle_user"
    },
    photo_upload: {
        list: "/get_photo_list",
        updatePhotoStatus: "/update_photo_status",
        updatePoster: "/update_poster_admin"
    }
};