
import React from "react";
import AuthUser from '../../components/AuthUser';
import { Routes } from "../../routes";

export default () => {
  const { getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  return (
    <>
    </>
  );
};
