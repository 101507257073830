import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Table from "../Table";
import { Form, Button, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../components/AuthUser";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import { Routes } from "../routes";

const Students = () => {
  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const columns = useMemo(
    () => [
      {
        Header: "Institute",
        accessor: "institute_name",
        isSorted: true
      },
      {
        Header: "Class",
        accessor: "class_name",
        isSorted: true
      },      
      {
        Header: "Section",
        accessor: "section_name",
        isSorted: true
      },      
      {
        Header: "Student",
        accessor: "name",
        isSorted: true
      },      
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer' }}>
              <div className="d-inline-block me-1">In-Active</div>
              <div className="form-check form-switch d-inline-block">
                <input type="checkbox" className="form-check-input" id={"status_" + original.id} defaultChecked={ original.status === 'ACTIVE' ? true : false } onChange={ (e) => toggleStatus(original.id, e) } style={{ cursor: 'pointer' }} />
                <label htmlFor={"status_" + original.id} className="form-check-label">Active</label>
              </div>
            </span>            
          </>            
        ),
        isSorted: true
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer' }} onClick={ () => showEditForm(original) }><FontAwesomeIcon icon={ faPencilAlt } /></span>            
          </>            
        )
      }
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showDefault, setShowDefault] = useState(false);
  const [cities, setCities] = useState(null);
  const [instByCity, setInstByCity] = useState(null);
  const [clsByInst, setClsByInst] = useState(null);
  const [sectionByCls, setSectionByCls] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [studentName, setStudentName] = useState("");
  const [studentCity, setStudentCity] = useState("");
  const [studentInst, setStudentInst] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentStatus, setStudentStatus] = useState("ACTIVE");
  const [studentSection, setStudentSection] = useState("");
  const [studentAddLoading, setStudentAddLoading] = useState(false);
  const [studentAddSuccess, setStudentAddSuccess] = useState(null);
  const [studentAddError, setStudentAddError] = useState(null);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [studentEditLoading, setStudentEditLoading] = useState(false);
  const [studentEditSuccess, setStudentEditSuccess] = useState(null);
  const [studentEditError, setStudentEditError] = useState(null);
  const [studentToggleLoading, setStudentToggleLoading] = useState(false);
  const [studentToggleError, setStudentToggleError] = useState(null);

  const handleClose = () => {
    setStudentAddError(null);
    setShowDefault(false);
  }
  const showAddForm = () => {
    setShowDefault(true);
  }

  const saveStudent = (e) => {
    e.preventDefault();
    setStudentAddError(null);
    setStudentAddLoading(true);    
    // api call
    http.post(Apis.student.save, {name: studentName, inst_uuid: studentInst, class_uuid: studentClass, section_uuid: studentSection, status: studentStatus})
    .then(res => {
        setStudentAddLoading(false);
        setStudentAddSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setStudentAddLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setStudentAddError(errorMsg);
        } else {
          setStudentAddError([err.response.data.message]);
        }
    });
  }

  const handleEditClose = () => {
    setStudentEditError(null);
    setStudentId(null);
    setStudentName("");
    setStudentCity("");
    setStudentInst("");
    setStudentClass("");
    setStudentSection("");
    setStudentStatus("ACTIVE");
    setShowEditDefault(false);
  }

  const showEditForm = (data) => {
    setStudentId(data.id);    
    setStudentName(data.name);
    setStudentCity(data.city_id);    
    setStudentStatus(data.status);
    getInst(data.city_id);
    getCls(data.institute_id);
    getSection(data.institute_id, data.class_id);
    setStudentSection(data.section_id);
    setShowEditDefault(true);
  }

  const updateStudent = (e) => {
    e.preventDefault();
    setStudentEditError(null);
    setStudentEditLoading(true);    
    // api call
    http.post(Apis.student.update, {student_uuid: studentId, name: studentName, inst_uuid: studentInst, class_uuid: studentClass, section_uuid: studentSection, status: studentStatus})
    .then(res => {
        setStudentEditLoading(false);
        setStudentEditSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setStudentEditLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setStudentEditError(errorMsg);
        } else {
          setStudentEditError([err.response.data.message]);
        }
    });
  }

  const toggleStatus = (studentId, e) => {
    e.persist();
    const status = e.target.checked ? "ACTIVE" : "INACTIVE";
    setStudentToggleError(null);
    setStudentToggleLoading(true);    
    // api call
    http.post(Apis.student.toggleStatus, {student_uuid: studentId, status: status})
    .then(res => {
        setStudentToggleLoading(false);        
    })
    .catch(err => {
        document.getElementById(e.target.id).checked = !e.target.checked;
        setStudentToggleLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setStudentToggleError(errorMsg);
        } else {
          setStudentToggleError([err.response.data.message]);
        }
    });
  }
  
  useEffect(() => {
    // api call
    http.get(Apis.cities)
    .then(res => {        
        setCities(res.data.data);
    });
  }, []);

  const getInst = (cityId) => {
    setStudentInst("");
    setStudentClass("");
    setStudentSection("");
    // api call
    http.get(`${Apis.inst_by_city}/${cityId}`)
    .then(res => {        
        setInstByCity(res.data.data);
    });
  }

  const getCls = (instId) => {
    setStudentInst(instId);
    // api call
    http.get(`${Apis.cls_by_inst}/${instId}`)
    .then(res => {        
        setClsByInst(res.data.data);
    });
  }

  const getSection = (instId, classId) => {
    setStudentClass(classId);
    // api call
    http.get(`${Apis.section_by_cls}/${instId}/${classId}`)
    .then(res => {        
        setSectionByCls(res.data.data);
    });
  }

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.student.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (sessionStorage.getItem("academic_year")) {
      apiString += `&academic_year=${sessionStorage.getItem("academic_year")}`;
    }
    if (searchText) {
      apiString += `&search_keyword=${searchText}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        setData(res.data.data.data);
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 mt-3">
            <h5>Student Management</h5>
          </div>
          <div className="col-sm-6 text-right mt-2">
            <button className="btn cust-button me-2" onClick={showAddForm}>+</button>
          </div>
        </div>
        {
            error && <ErrorMsg errors={error} />
        }
        {
            studentToggleError && <ErrorMsg errors={studentToggleError} />
        }
        {
            studentToggleLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Add Student</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {
              studentAddSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {studentAddSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              studentAddError && <ErrorMsg errors={studentAddError} />
          }
          <Form className="mt-4" onSubmit={ saveStudent }>

            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" onChange={(e) => setStudentName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select onChange={(e) => getInst(e.target.value)}>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Institute<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select value={studentInst} onChange={(e) => getCls(e.target.value)}>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                  return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Class<span style={{ color: "red" }}> *</span></Form.Label>
                <Form.Select value={studentClass} onChange={(e) => getSection(studentInst, e.target.value)}>
                    <option defaultValue value="">Select class...</option>
                    {
                      clsByInst && clsByInst.map((cls, i) => {
                                      return <option key={i} value={cls.id}>{cls.name}</option>
                                    })
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Section</Form.Label>
                <Form.Select value={studentSection} onChange={(e) => setStudentSection(e.target.value)}>
                    <option defaultValue value="">Select section...</option>
                    {
                      sectionByCls && sectionByCls.map((section, i) => {
                                      return <option key={i} value={section.id}>{section.name}</option>
                                    })
                    }
                </Form.Select>
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={studentAddLoading}>
                Save{studentAddLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showEditDefault} onHide={handleEditClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Edit Student</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleEditClose} />
        </Modal.Header>
        <Modal.Body>
          {
              studentEditSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {studentEditSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              studentEditError && <ErrorMsg errors={studentEditError} />
          }
          <Form className="mt-4" onSubmit={ updateStudent }>

            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" value={studentName} onChange={(e) => setStudentName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select value={studentCity} disabled>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Institute</Form.Label>
              <Form.Select value={studentInst} disabled>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                  return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Class<span style={{ color: "red" }}> *</span></Form.Label>
                <Form.Select value={studentClass} onChange={(e) => getSection(studentInst, e.target.value)}>
                    <option defaultValue value="">Select class...</option>
                    {
                      clsByInst && clsByInst.map((cls, i) => {
                                      return <option key={i} value={cls.id}>{cls.name}</option>
                                    })
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Section</Form.Label>
                <Form.Select value={studentSection} onChange={(e) => setStudentSection(e.target.value)}>
                    <option defaultValue value="">Select section...</option>
                    {
                      sectionByCls && sectionByCls.map((section, i) => {
                                      return <option key={i} value={section.id}>{section.name}</option>
                                    })
                    }
                </Form.Select>
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={studentEditLoading}>
                Update{studentEditLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Students;