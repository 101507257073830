import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import AuthUser from "../components/AuthUser";

// pages
import Home from './Home';
import DashboardOverview from "./dashboard/DashboardOverview";
import Cities from './Cities';
import Institutes from './Institutes';
import Cls from './Cls';
import Sections from './Sections';
import Students from './Students';
import Staffs from './Staffs';
import PhotoUploads from './PhotoUploads';
import Roles from './Roles';
import Users from './Users';
import ChangePassword from './ChangePassword';

import Signin from './Signin';
// import ForgotPassword from "./examples/ForgotPassword";
// import ResetPassword from "./examples/ResetPassword";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";
import Forbidden from './examples/Forbidden';

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => {
  const { user } = AuthUser();
  const allowedArr = [true, undefined];  
  return (
    <Switch>
      <RouteWithLoader exact path={Routes.Home.path} component={Home} />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.ChangePassword.path} component={ChangePassword} />
      {/* <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} /> */}
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
      <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
      <RouteWithLoader exact path={Routes.Forbidden.path} component={Forbidden} />

      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
      {
        allowedArr.includes(user?.features?.city_management) ?
        <RouteWithSidebar exact path={Routes.Cities.path} component={Cities} /> :
        <RouteWithLoader exact path={Routes.Cities.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.institute_management) ?
        <RouteWithSidebar exact path={Routes.Institutes.path} component={Institutes} /> :
        <RouteWithLoader exact path={Routes.Institutes.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.class_management) ?
        <RouteWithSidebar exact path={Routes.Cls.path} component={Cls} /> :
        <RouteWithLoader exact path={Routes.Cls.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.section_management) ?
        <RouteWithSidebar exact path={Routes.Sections.path} component={Sections} /> :
        <RouteWithLoader exact path={Routes.Sections.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.student_management) ?
        <RouteWithSidebar exact path={Routes.Students.path} component={Students} /> :
        <RouteWithLoader exact path={Routes.Students.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.staff_management) ?
        <RouteWithSidebar exact path={Routes.Staffs.path} component={Staffs} /> :
        <RouteWithLoader exact path={Routes.Staffs.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.photo_uploads) ?
        <RouteWithSidebar exact path={Routes.PhotoUploads.path} component={PhotoUploads} /> :
        <RouteWithLoader exact path={Routes.PhotoUploads.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.role_management) ?
        <RouteWithSidebar exact path={Routes.Roles.path} component={Roles} /> :
        <RouteWithLoader exact path={Routes.Roles.path} component={Forbidden} />
      }
      {
        allowedArr.includes(user?.features?.ybp_staff_management) ?
        <RouteWithSidebar exact path={Routes.ybpStaffs.path} component={Users} /> :
        <RouteWithLoader exact path={Routes.ybpStaffs.path} component={Forbidden} />
      }

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
}
