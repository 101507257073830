import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, InputGroup } from "@themesberg/react-bootstrap";
import { Routes } from "./routes";
import AuthUser from "./components/AuthUser";
import { Apis } from "./common/Apis";
import { useParams } from "react-router-dom";

const Table = ({ columns, data, fetchData, loading, pageCount: controlledPageCount, loadData = true }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      // Get the state from the instance
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 }, // Pass our hoisted table state
        manualPagination: true, // Tell the usePagination
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        pageCount: controlledPageCount,
        autoResetSelectedRows: false,
        getRowId: (row) => row.key
      },
      usePagination
    );

    const { productType, cityId, instId, classId, sectionId } = useParams();

    const { http } = AuthUser();
    const [searchText, setSearchText] = useState(null);
    const [colName, setColName] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);    
    const [cities, setCities] = useState(null);
    const [instByCity, setInstByCity] = useState(null);
    const [clsByInst, setClsByInst] = useState(null);
    const [sectionByCls, setSectionByCls] = useState(null);
    const [studentByClsSection, setStudentByClsSection] = useState(null);
    const [product, setProduct] = useState(productType || "CompositePhoto");
    const [city, setCity] = useState(cityId || "");
    const [inst, setInst] = useState(instId || "");
    const [cls, setCls] = useState(classId || "");
    const [section, setSection] = useState(sectionId || "");
    const [student, setStudent] = useState("");
    const [enableDataLoad, setEnableDataLoad] = useState(false);

    const setTextToState = (e) => {
      if (e.target.value !== "") {
        if (e.keyCode === 13) {
          setSearchText(e.target.value);
        }
      } else {
        setSearchText(e.target.value);
      }
    }

    const setValuesToState = (colName, sortOrder) => {
      setColName(colName);
      setSortOrder(sortOrder);
    }
  
    useEffect(() => {
      // api call
      http.get(Apis.cities)
      .then(res => {        
          setCities(res.data.data);
      });
    }, []);
  
    const getInst = (cityId) => {
      setCity(cityId);
      // api call
      http.get(`${Apis.inst_by_city}/${cityId}`)
      .then(res => {        
          setInstByCity(res.data.data);
      });
    }
  
    const getCls = (instId, taggedClassIds = []) => {
      setInst(instId);
      // api call
      http.get(`${Apis.cls_by_inst}/${instId}`)
      .then(res => {
          if (taggedClassIds.length > 0)
            setClsByInst(res.data.data.filter(obj => taggedClassIds.includes(obj.id)));
          else        
            setClsByInst(res.data.data);
      });
    }
  
    const getSection = (instId, classId) => {
      setCls(classId);
      getStudent(instId, classId);
      // api call
      http.get(`${Apis.section_by_cls}/${instId}/${classId}`)
      .then(res => {        
          setSectionByCls(res.data.data);
      });
    }

    const getStudent = (instId, classId, sectionId = "") => {
      setSection(sectionId);
      // api call
      http.get(`${Apis.get_students_admin}/${instId}/${classId}/${sectionId}`)
      .then(res => {        
          setStudentByClsSection(res.data.data);
      });
    }

    useEffect(() => {
      let taggedClassIds = [];
      if (sessionStorage.getItem("taggedClassIds") !== null) {
        taggedClassIds = JSON.parse(sessionStorage.getItem("taggedClassIds"));
      }
      if (cityId)
        getInst(cityId);
      if (instId)
        getCls(instId, taggedClassIds);
      if (instId && classId)
        getSection(instId, classId);      
    }, []);

    const fetchPhotoAlbums = (e) => {
      e.preventDefault();
      setEnableDataLoad(true);
      fetchData({ pageIndex, pageSize, product, city, inst, cls, section, student });
    }

    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
      if (loadData || enableDataLoad) {
        if (enableDataLoad)
          fetchData({ pageIndex, pageSize, product, city, inst, cls, section, student });
        else if (loadData)
          fetchData({ pageIndex, pageSize, searchText, colName, sortOrder });
      }
    }, [fetchData, pageIndex, pageSize, searchText, colName, sortOrder]);
  
    // Render the UI for your table
    return (
      <>
        { window.location.href.includes(Routes.PhotoUploads.path.slice(0, 14)) && 
          <div style={{ padding: '10px' }}>
            <Form onSubmit={ fetchPhotoAlbums }>
              <div className="row">
                <div className="col">
                  <Form.Label>Products<span style={{ color: "red" }}> *</span></Form.Label>
                  <Form.Select onChange={(e) => setProduct(e.target.value)}>
                    <option defaultValue value="CompositePhoto" selected={productType === "CompositePhoto" || false}>Composite Albums</option>
                    <option value="MemoryBook" selected={productType === "MemoryBook" || false}>Memory Books</option>
                  </Form.Select>
                </div>
                <div className="col">
                  <Form.Label>Cities</Form.Label>
                  <Form.Select onChange={(e) => getInst(e.target.value)}>
                    <option defaultValue value="">Select city...</option>
                    {
                      cities && cities.map((city, i) => {
                                  return <option key={i} value={city.id} selected={cityId === city.id || false}>{city.name}</option>
                                })
                    }                
                  </Form.Select>
                </div>
                <div className="col">
                  <Form.Label>Institutes</Form.Label>
                  <Form.Select onChange={(e) => getCls(e.target.value)}>
                    <option defaultValue value="">Select institute...</option>
                    {
                      instByCity && instByCity.map((inst, i) => {
                                      return <option key={i} value={inst.id} selected={instId === inst.id || false}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                    })
                    }
                  </Form.Select>
                </div>            
                <div className="col">
                  <Form.Label>Classes</Form.Label>
                  <Form.Select onChange={(e) => getSection(inst, e.target.value)}>
                    <option defaultValue value="">Select class...</option>
                    {
                      clsByInst && clsByInst.map((cls, i) => {
                                      return <option key={i} value={cls.id} selected={classId === cls.id || false}>{cls.name}</option>
                                    })
                    }
                  </Form.Select>
                </div>
                <div className="col">
                  <Form.Label>Sections</Form.Label>
                  <Form.Select onChange={(e) => getStudent(inst, cls, e.target.value)}>
                    <option defaultValue value="">Select section...</option>
                    {
                      sectionByCls && sectionByCls.map((section, i) => {
                                      return <option key={i} value={section.id} selected={sectionId === section.id || false}>{section.name}</option>
                                    })
                    }
                  </Form.Select>
                </div>
                { product === "MemoryBook" && 
                  <div className="col">
                    <Form.Label>Students</Form.Label>
                    <Form.Select onChange={(e) => setStudent(e.target.value)}>
                      <option defaultValue value="">Select student...</option>
                      {
                        studentByClsSection && studentByClsSection.map((student, i) => {
                                        return <option key={i} value={student.id}>{student.name}</option>
                                      })
                      }
                    </Form.Select>
                  </div>
                }
              </div>
              <button type="submit" className="btn btn-small cust-button mt-2" disabled={loading}>
                Go
                {
                  loading ? <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
                  : <span style={{ marginLeft: '5px' }}><FontAwesomeIcon icon={ faArrowRight } /></span>
                }
              </button>
            </Form>
          </div>
        }
        <div className="row mt-2">
          { !window.location.href.includes(Routes.PhotoUploads.path.slice(0, 14)) && 
            <div className="col-sm-4">
              <InputGroup style={{ border: "1px solid" }}>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" id="search" placeholder="Press enter to search" onKeyUp={(e) => setTextToState(e)} />
              </InputGroup>
            </div>
          }
          <div className={window.location.href.includes(Routes.PhotoUploads.path.slice(0, 14)) ? "col-sm-12 text-right mt-2" : "col-sm-8 text-right mt-2"}>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>          
        </div>                
        <div className="table-responsive">        
          <table className="table table-light table-bordered table-striped table-hover" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th style={{ backgroundColor: 'lightgrey' }} {...column.getHeaderProps()}>
                      {column.render("Header")}
                      <span>
                        {
                          column.isSorted
                          ? (colName && sortOrder)
                            ? (column.id === colName)
                              ? (sortOrder === "desc")
                                ? <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "asc")}> 🔽</span>
                                : <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "desc")}> 🔼</span>
                              : <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "desc")}> 🔼</span>
                            : <span style={{ cursor: "pointer" }} onClick={() => setValuesToState(column.id, "desc")}> 🔼</span>
                          : ""
                        }
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const TD = window.location.href.includes(Routes.PhotoUploads.path.slice(0, 14)) ?
                        <td {...cell.getCellProps()} style={{ wordWrap: 'break-word', whiteSpace: 'normal', minWidth: '100px', maxWidth: '100px' }}>{cell.render("Cell")}</td>
                        : <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      return (
                        TD
                      );
                    })}
                  </tr>
                );
              })}
              <tr>
                {loading ? (
                  // Use our custom loading state to show a loading indicator
                  <td colSpan="10000"><span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span></td>
                ) : (
                  <td colSpan="10000">
                    Showing {page.length} of ~{controlledPageCount * pageSize}{" "}
                    results
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
        <div className="row">
          <div className="pagination">
            <div className="col-sm-6">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>&nbsp;&nbsp;
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {"<"}
              </button>&nbsp;&nbsp;
              <span>
                Page&nbsp;
                <strong>
                  {pageIndex + 1} / {pageOptions.length}
                </strong>&nbsp;&nbsp;
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>&nbsp;&nbsp;
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {">>"}
              </button>&nbsp;&nbsp;&nbsp;&nbsp;              
            </div>            
          </div>
        </div>
      </>
    );
}
export default Table;