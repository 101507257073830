import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Table from "../Table";
import { Form, Button, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../components/AuthUser";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import { Routes } from "../routes";

const Roles = () => {
  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isSorted: true
      },
      {
        Header: "Description",
        accessor: "description"
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer', marginRight: "10px" }} onClick={ () => showEditForm(original) }><FontAwesomeIcon icon={ faPencilAlt } /></span>
            <span style={{ cursor: 'pointer' }} onClick={ () => deleteRole(original.id) }><FontAwesomeIcon icon={ faTrashAlt } /></span>            
          </>            
        )
      }
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showDefault, setShowDefault] = useState(false);
  const [features, setFeatures] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [roleDesc, setRoleDesc] = useState("");
  const [roleFeature, setRoleFeature] = useState([]);
  const [roleAddLoading, setRoleAddLoading] = useState(false);
  const [roleAddSuccess, setRoleAddSuccess] = useState(null);
  const [roleAddError, setRoleAddError] = useState(null);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [roleEditLoading, setRoleEditLoading] = useState(false);
  const [roleEditSuccess, setRoleEditSuccess] = useState(null);
  const [roleEditError, setRoleEditError] = useState(null);
  const [roleDeleteLoading, setRoleDeleteLoading] = useState(false);
  const [roleDeleteError, setRoleDeleteError] = useState(null);

  const handleClose = () => {
    setRoleAddError(null);
    setShowDefault(false);
  }
  const showAddForm = () => {
    setShowDefault(true);
  }

  const setFeaturesToState = (e) => {
    if (e.target.checked) {
      setRoleFeature([...roleFeature, e.target.value]);
    } else {
      setRoleFeature(roleFeature.filter(fId => fId !== e.target.value));
    }
  }

  const saveRole = (e) => {
    e.preventDefault();
    setRoleAddError(null);
    setRoleAddLoading(true);    
    // api call
    http.post(Apis.role.save, {name: roleName, description: roleDesc, feature_uuids: roleFeature})
    .then(res => {
        setRoleAddLoading(false);
        setRoleAddSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setRoleAddLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setRoleAddError(errorMsg);
        } else {
          setRoleAddError([err.response.data.message]);
        }
    });
  }

  const handleEditClose = () => {
    setRoleEditError(null);
    setRoleId(null);
    setRoleName("");
    setRoleDesc("");
    setShowEditDefault(false);
  }

  const showEditForm = (data) => {
    setRoleId(data.id);    
    setRoleName(data.name);
    setRoleDesc(data.description);
    const fIds = data.features.map(obj => obj.id);
    setRoleFeature(fIds);
    setShowEditDefault(true);
  }

  const updateRole = (e) => {
    e.preventDefault();
    setRoleEditError(null);
    setRoleEditLoading(true);    
    // api call
    http.post(Apis.role.update, {role_uuid: roleId, name: roleName, description: roleDesc, feature_uuids: roleFeature})
    .then(res => {
        setRoleEditLoading(false);
        setRoleEditSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setRoleEditLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setRoleEditError(errorMsg);
        } else {
          setRoleEditError([err.response.data.message]);
        }
    });
  }

  const deleteRole = (roleId) => {
    const resp = window.confirm("Do you want to delete this role?");
    if (resp) {
      setRoleDeleteError(null);
      setRoleDeleteLoading(true);
      http.post(Apis.role.delete, {role_uuid: roleId})
      .then(res => {
        setRoleDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setRoleDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setRoleDeleteError(errorMsg);
        } else {
          setRoleDeleteError([err.response.data.message]);
        }
      });
    }
  }

  useEffect(() => {
    // api call
    http.get(Apis.get_features)
    .then(res => {        
        setFeatures(res.data.data);
    });
  }, []);

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.role.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText) {
      apiString += `&search_keyword=${searchText}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        setData(res.data.data.data);
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 mt-3">
            <h5>Role Management</h5>
          </div>
          <div className="col-sm-6 text-right mt-2">
            <button className="btn cust-button me-2" onClick={showAddForm}>+</button>
          </div>
        </div>
        {
            error && <ErrorMsg errors={error} />
        }
        {
            roleDeleteError && <ErrorMsg errors={roleDeleteError} />
        }
        {
            roleDeleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>      

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Add Role</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {
              roleAddSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {roleAddSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              roleAddError && <ErrorMsg errors={roleAddError} />
          }
          <Form className="mt-4" onSubmit={ saveRole }>
            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" onChange={(e) => setRoleName(e.target.value)} />
            </Form.Group>
            <Form.Group id="description" className="mb-4">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows="3" onChange={(e) => setRoleDesc(e.target.value)} />
            </Form.Group>
            <Form.Group id="features" className="mb-4">
              <Form.Label>Features</Form.Label>
              <fieldset>
                <strong>App</strong><hr />
                <div className="mb-2">
                {
                  features && features.app.map((ft, i) => {
                              return <Form.Check key={i} className="d-inline-block me-1" label={ft.name} value={ft.id} title={ft.description} onChange={(e) => setFeaturesToState(e)} />
                            })
                }
                </div>
                <strong>Web</strong><hr />
                <div>
                {
                  features && features.web.map((ft, i) => {
                              return <Form.Check key={i} className="d-inline-block me-1" label={ft.name} value={ft.id} title={ft.description} onChange={(e) => setFeaturesToState(e)} />
                            })
                }
                </div>
              </fieldset>
            </Form.Group>
            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={roleAddLoading}>
                Save{roleAddLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showEditDefault} onHide={handleEditClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Edit Role</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleEditClose} />
        </Modal.Header>
        <Modal.Body>
          {
              roleEditSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {roleEditSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              roleEditError && <ErrorMsg errors={roleEditError} />
          }
          <Form className="mt-4" onSubmit={ updateRole }>
            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" value={roleName} onChange={(e) => setRoleName(e.target.value)} />
            </Form.Group>
            <Form.Group id="description" className="mb-4">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows="3" value={roleDesc} onChange={(e) => setRoleDesc(e.target.value)} />
            </Form.Group>
            <Form.Group id="features" className="mb-4">
              <Form.Label>Features</Form.Label>
              <fieldset>
                <strong>App</strong><hr />
                <div className="mb-2">
                {
                  features && features.app.map((ft, i) => {
                              return <Form.Check key={i} className="d-inline-block me-1" label={ft.name} value={ft.id} title={ft.description} defaultChecked={roleFeature.includes(ft.id) || false} onChange={(e) => setFeaturesToState(e)} />
                            })
                }
                </div>
                <strong>Web</strong><hr />
                <div>
                {
                  features && features.web.map((ft, i) => {
                              return <Form.Check key={i} className="d-inline-block me-1" label={ft.name} value={ft.id} title={ft.description} defaultChecked={roleFeature.includes(ft.id) || false} onChange={(e) => setFeaturesToState(e)} />
                            })
                }
                </div>
              </fieldset>
            </Form.Group>
            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={roleEditLoading}>
                Update{roleEditLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Roles;