import React, { useState, useRef, useMemo, useCallback } from "react";
import Table from "../Table";
import { Form, Button, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faDownload, faEye, faFileUpload, faRecycle } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../components/AuthUser";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import { Routes } from "../routes";

const PhotoUploads = () => {
  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const columns = useMemo(
    () => [            
      {
        Header: "Institute",
        accessor: "institute_name"
      },      
      {
        Header: "Class",
        accessor: "class_name"
      },
      {
        Header: "Section",
        accessor: "section_name"
      },
      {
        Header: "Academic Year",
        accessor: "academic_year"
      },
      {
        Header: "Uploader",
        accessor: "user_name"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "Collaged Photo Status",
        accessor: "poster_status"
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer' }} onClick={ () => previewPhotos(original.student_photos, original.staffs_photos, original.general_photos) }><FontAwesomeIcon title="Preview Photos" icon={ faEye } /> ({ original.student_photos[0].product_type === "MemoryBook" ? `${original.student_photo_count}` : `${original.student_photo_count} / ${original.total_student_count}` })</span>
            {
              original.poster_status !== "ACCEPTED" && <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} onClick={ () => showPosterModal(original.student_photos[0].product_type, original.id, original.poster_url) }><FontAwesomeIcon title="Upload Collaged Photo" icon={ faFileUpload } /></button>
            }            
          </>            
        )
      }
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showDefault, setShowDefault] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [staffPhotos, setStaffPhotos] = useState([]);
  const [generalPhotos, setGeneralPhotos] = useState([]);
  const [photoIds, setPhotoIds] = useState([]);
  const [staffPhotoIds, setStaffPhotoIds] = useState([]);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [updateStatusSuccess, setUpdateStatusSuccess] = useState(null);
  const [updateStatusError, setUpdateStatusError] = useState(null);
  const [showPosterDefault, setShowPosterDefault] = useState(false);
  const [productId, setProductId] = useState(null);
  const [productType, setProductType] = useState(null);
  const [file, setFile] = useState(null);
  const [posterUrl, setPosterUrl] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  
  const handleClose = () => {
    setUpdateStatusError(null);
    setPhotos([]);
    setStaffPhotos([]);
    setGeneralPhotos([]);
    setShowDefault(false);
  }

  const previewPhotos = (photos, staffPhotos, generalPhotos) => {
    setPhotos(photos);
    setStaffPhotos(staffPhotos);
    setGeneralPhotos(generalPhotos);
    setShowDefault(true);
  }

  const setPhotoIdsToState = (e) => {
    if (e.target.checked) {
      setPhotoIds([...photoIds, e.target.value]);
    } else {
      setPhotoIds(photoIds.filter(pId => pId !== e.target.value));
    }
  }

  const setStaffPhotoIdsToState = (e) => {
    if (e.target.checked) {
      setStaffPhotoIds([...staffPhotoIds, e.target.value]);
    } else {
      setStaffPhotoIds(staffPhotoIds.filter(pId => pId !== e.target.value));
    }
  }

  const selectAllPhotos = () => {
    const checkBoxes = document.getElementsByName("photo_check[]");
    const staffCheckBoxes = document.getElementsByName("staff_photo_check[]");
    let values = [], staffValues = [];

    if (photoIds.length > 0 || staffPhotoIds.length > 0) {
      for (let i = 0, l = checkBoxes.length; i < l; i++) {
        checkBoxes[i].checked = false;
      }
      for (let i = 0, l = staffCheckBoxes.length; i < l; i++) {
        staffCheckBoxes[i].checked = false;
      }
    } else {      
      for (let i = 0, l = checkBoxes.length; i < l; i++) {
        if (!checkBoxes[i].disabled) {
          checkBoxes[i].checked = true;
          values.push(checkBoxes[i].value);
        }
      }
      for (let i = 0, l = staffCheckBoxes.length; i < l; i++) {
        if (!staffCheckBoxes[i].disabled) {
          staffCheckBoxes[i].checked = true;
          staffValues.push(staffCheckBoxes[i].value);
        }
      }      
    }
    setPhotoIds(values);
    setStaffPhotoIds(staffValues);
  }

  const downloadImage = (e, URL) => {
      e.preventDefault();
      const fileName = URL.split('/').pop().split('?')[0];

      const myHeaders = new Headers();
      myHeaders.append('pragma', 'no-cache');
      myHeaders.append('cache-control', 'no-cache');
      
      fetch(`https://${URL}`, {
        method: "GET",
        headers: myHeaders    
      })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  const downloadAllPhotos = () => {
      const imgURLs = document.getElementsByName("img_urls[]");
      const myHeaders = new Headers();
      myHeaders.append('pragma', 'no-cache');
      myHeaders.append('cache-control', 'no-cache');
      
      for (let i = 0, l = imgURLs.length; i < l; i++) {
        const fileName = imgURLs[i].getAttribute("data-photo-url").split('/').pop().split('?')[0];
        
        fetch(`https://${imgURLs[i].getAttribute("data-photo-url")}`, {
          method: "GET",
          headers: myHeaders    
        })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.error(err);
        });
      }
  };

  const updateStatus = (status) => {
    if (photoIds.length === 0 && staffPhotoIds.length === 0) {
      setUpdateStatusError(["Please select a photo to proceed!"]);
      return false;
    }
    setUpdateStatusError(null);
    setUpdateStatusLoading(true);
    http.post(Apis.photo_upload.updatePhotoStatus, {photo_uuids: photoIds, photo_status: status, staff_uuids: staffPhotoIds, staff_status: status})
    .then(res => {
      setUpdateStatusLoading(false);
      setUpdateStatusSuccess(res.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 800);
    })
    .catch(err => {
      setUpdateStatusLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setUpdateStatusError(errorMsg);
      } else {
        setUpdateStatusError([err.response.data.message]);
      }
    });
  }

  const handlePosterClose = () => {
    setUploadError(null);
    setUploadSuccess(null);
    setProductType(null);
    setProductId(null);
    setPosterUrl(null);
    setFile(null);
    setShowPosterDefault(false);
  }

  const showPosterModal = (productType, productId, posterUrl) => {
    setProductType(productType);
    setProductId(productId);
    setPosterUrl(posterUrl);
    setShowPosterDefault(true);
  }

  const setFileToState = (e) => {
    setFile(e.target.files[0]);
  }

  const uploadFile = (e) => {
    e.preventDefault();
    setUploadError(null);
    setUploadLoading(true);
    
    const formData = new FormData();
    formData.append('files[]', file);
    formData.append('type', 'poster');
    formData.append('product_type', productType);
    formData.append('product_uuid', productId);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    
    http.post(Apis.upload_file, formData, config)
    .then(res => {
        http.post(Apis.photo_upload.updatePoster, {product_type: productType, product_uuid: productId, poster_url: res.data.data.files[0].url})
        .then(res => {
          setUploadLoading(false);
          setUploadSuccess(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 800);
        })
        .catch(err => {
          setUploadLoading(false);
          if (err.response.status === 400) {
              const errorMsg = parseError(err.response.data.error);
              setUploadError(errorMsg);
          } else {
              setUploadError([err.response.data.message]);
          }
        });        
    })
    .catch(err => {
        setUploadLoading(false);
        if (err.response.status === 400) {
            const errorMsg = parseError(err.response.data.error);
            setUploadError(errorMsg);
        } else {
            setUploadError([err.response.data.message]);
        }
    });
  }

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, product, city, inst, cls, section, student }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.photo_upload.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (sessionStorage.getItem("academic_year")) {
      apiString += `&academic_year=${sessionStorage.getItem("academic_year")}`;
    }

    let jsonData = {product_type: product};
    if (city !== "")
      jsonData.city_uuid = city;
    if (inst !== "")
      jsonData.inst_uuid = inst;
    if (cls !== "")
      jsonData.class_uuid = cls;
    if (section !== "")
      jsonData.section_uuid = section;
    if (student !== "")
      jsonData.student_uuid = student;

    http.post(apiString, jsonData)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        setData(res.data.data.data);
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        {
            error && <ErrorMsg errors={error} />
        }                
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          loadData={false}
        />
        {/* Server side table component */}
      </div>
      
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Preview Photos</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>          
          {
              updateStatusSuccess && <div className="row mt-3">
                                        <div className="col">
                                            <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                                <strong>Success!</strong> {updateStatusSuccess}
                                            </div>
                                        </div>
                                    </div>
          }
          {
              updateStatusError && <ErrorMsg errors={updateStatusError} />
          }
          <div>
            <Button variant="success" className="me-2" onClick={() => updateStatus("ACCEPTED")} disabled={updateStatusLoading}>
              Accept
            </Button>
            <Button variant="danger" onClick={() => updateStatus("RESEND")} disabled={updateStatusLoading}>
              Reject
            </Button>
          </div>
          {
              updateStatusLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
          }
          <div className="text-right">
            <button className="btn btn-small cust-button mt-2 me-2" onClick={ selectAllPhotos }>Select All</button>
            <button className="btn btn-small cust-button mt-2" onClick={ downloadAllPhotos }>Download All Photos</button>
          </div>
          <p className="mt-4"><strong>Student Photos { photos.length > 0 && (photos[0].product_type === "MemoryBook" && `(${ photos[0].student_name })`) }</strong></p><hr />          
          <div className="row">                        
            {
              photos && photos.map((photoObj, i) => {
                return (
                        <div key={i} className="col-sm-6">
                          <span>
                            {
                              (photoObj.status === "IN REVIEW") && <FontAwesomeIcon icon={ faEye } />
                            }
                            {
                              (photoObj.status === "ACCEPTED") && <FontAwesomeIcon icon={ faCheckCircle } />
                            }
                            {
                              (photoObj.status === "RESEND") && <FontAwesomeIcon icon={ faRecycle } />
                            }
                            <span title="Download Image" name="img_urls[]" data-photo-url={ photoObj.photo_url.slice(7) } style={{ cursor: 'pointer' }} onClick={(e) => downloadImage(e, photoObj.photo_url.slice(7))}>
                              <FontAwesomeIcon icon={ faDownload } />
                            </span>
                          </span>
                          <Form.Check name="photo_check[]" value={photoObj.id} disabled={ photoObj.status !== "IN REVIEW" } onChange={(e) => setPhotoIdsToState(e)} />
                          {
                            photoObj.product_type !== "MemoryBook" && <h6>{photoObj.student_name}</h6>
                          }
                          <img src={photoObj.photo_url} alt="image" className="img-thumbnail" style={{width: "200px", height: "200px"}} />
                          <p>{photoObj.comment}</p>                          
                        </div>
                      )
              })
            }
          </div>
          {
            (staffPhotos && staffPhotos.length > 0) &&
            <>
              <p className="mt-4"><strong>Staff Photos</strong></p><hr />
              <div className="row">
                {
                  staffPhotos.map((photoObj, i) => {
                    return (
                            <div key={i} className="col-sm-6">
                              <span>
                                {
                                  (photoObj.status === "IN REVIEW") && <FontAwesomeIcon icon={ faEye } />
                                }
                                {
                                  (photoObj.status === "ACCEPTED") && <FontAwesomeIcon icon={ faCheckCircle } />
                                }
                                {
                                  (photoObj.status === "RESEND") && <FontAwesomeIcon icon={ faRecycle } />
                                }
                                <span title="Download Image" name="img_urls[]" data-photo-url={ photoObj.photo_url.slice(7) } style={{ cursor: 'pointer' }} onClick={(e) => downloadImage(e, photoObj.photo_url.slice(7))}>
                                  <FontAwesomeIcon icon={ faDownload } />
                                </span>
                              </span>
                              <Form.Check name="staff_photo_check[]" value={photoObj.id} disabled={ photoObj.status !== "IN REVIEW" } onChange={(e) => setStaffPhotoIdsToState(e)} />
                              <h6>{photoObj.name}</h6>
                              <img src={photoObj.photo_url} alt="image" className="img-thumbnail" style={{width: "200px", height: "200px"}} />
                              <p>{photoObj.designation}</p>
                            </div>
                          )
                  })
                }
              </div>
            </>
          }
          {
            (generalPhotos && generalPhotos.length > 0) &&
            <>
              <p className="mt-4"><strong>General Photos ({generalPhotos[0].category_name})</strong></p><hr />
              <div className="row">
                {
                  generalPhotos.map((photoObj, i) => {
                    return (
                            <div key={i} className="col-sm-6">
                              <div>
                                <span title="Download Image" name="img_urls[]" data-photo-url={ photoObj.photo_url.slice(7) } style={{ cursor: 'pointer' }} onClick={(e) => downloadImage(e, photoObj.photo_url.slice(7))}>
                                  <FontAwesomeIcon icon={ faDownload } />
                                </span>
                              </div>
                              <img src={photoObj.photo_url} alt="image" className="img-thumbnail" style={{width: "200px", height: "200px"}} />
                              <p>{photoObj.note}</p>
                            </div>
                          )
                  })
                }
              </div>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      
      <Modal as={Modal.Dialog} centered show={showPosterDefault} onHide={handlePosterClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
            <Modal.Title className="h6">Collaged Photo Upload</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handlePosterClose} />
        </Modal.Header>
        <Modal.Body>
            {
                uploadSuccess && <div className="row mt-3">
                                    <div className="col">
                                        <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                            <strong>Success!</strong> {uploadSuccess}
                                        </div>
                                    </div>
                                </div>
            }
            {
                uploadError && <ErrorMsg errors={uploadError} />
            }
            <Form className="mt-4" onSubmit={ uploadFile }>
                <Form.Group id="file" className="mb-4">
                    <Form.Control type="file" accept=".jpeg, .jpg, .png, .webp, .pdf" onChange={ setFileToState } />
                    <small>Supported file types: Image (JPEG, JPG, PNG, WEBP) & PDF</small>
                    { posterUrl && <p><a href={posterUrl} target="_blank" rel="noopener noreferrer">Click to see preview</a></p> }
                </Form.Group>
                <div className="text-center">
                    <button type="submit" className="btn cust-button w-50" disabled={uploadLoading}>
                        Upload{uploadLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                    </button>
                </div>
            </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>      
    </>
  );
}
export default PhotoUploads;