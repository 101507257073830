import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Table from "../Table";
import { Form, Button, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faImages, faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../components/AuthUser";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";

const Sections = () => {
  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const columns = useMemo(
    () => [
      {
        Header: "Institute",
        accessor: "institute_name",
        isSorted: true
      },
      {
        Header: "Section",
        accessor: "name",
        isSorted: true
      },
      {
        Header: "Classes",
        accessor: "tagged_classes"
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer', marginRight: "10px" }} onClick={ () => showEditForm(original) }><FontAwesomeIcon icon={ faPencilAlt } /></span>
            <span style={{ cursor: 'pointer' }} onClick={ () => deleteSection(original.id) }><FontAwesomeIcon icon={ faTrashAlt } /></span>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Composite Albums" onClick={ () => goToPhotoUploads(original.city_id, original.institute_id, original.class_id, original.tagged_class_ids, original.id, "CompositePhoto") }><FontAwesomeIcon icon={ faImages } /></button>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Memory Books" onClick={ () => goToPhotoUploads(original.city_id, original.institute_id, original.class_id, original.tagged_class_ids, original.id, "MemoryBook") }><FontAwesomeIcon icon={ faBook } /></button>            
          </>            
        )
      }
    ],
    []
  );

  const history = useHistory();
  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showDefault, setShowDefault] = useState(false);
  const [cities, setCities] = useState(null);
  const [instByCity, setInstByCity] = useState(null);
  const [clsByInst, setClsByInst] = useState(null);  
  const [sectionId, setSectionId] = useState(null);
  const [sectionName, setSectionName] = useState("");
  const [sectionCity, setSectionCity] = useState("");
  const [sectionInst, setSectionInst] = useState("");
  const [sectionClass, setSectionClass] = useState([]);
  const [sectionAddLoading, setSectionAddLoading] = useState(false);
  const [sectionAddSuccess, setSectionAddSuccess] = useState(null);
  const [sectionAddError, setSectionAddError] = useState(null);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [sectionEditLoading, setSectionEditLoading] = useState(false);
  const [sectionEditSuccess, setSectionEditSuccess] = useState(null);
  const [sectionEditError, setSectionEditError] = useState(null);
  const [sectionDeleteLoading, setSectionDeleteLoading] = useState(false);
  const [sectionDeleteError, setSectionDeleteError] = useState(null);

  const handleClose = () => {
    setSectionAddError(null);
    setShowDefault(false);
  }
  const showAddForm = () => {
    setShowDefault(true);
  }

  const setClassesToState = (e) => {
    const options = e.target.options;
    let value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSectionClass(value);
  }

  const saveSection = (e) => {
    e.preventDefault();
    setSectionAddError(null);
    setSectionAddLoading(true);    
    // api call
    http.post(Apis.section.save, {name: sectionName, inst_uuid: sectionInst, cls_uuid: sectionClass})
    .then(res => {
        setSectionAddLoading(false);
        setSectionAddSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setSectionAddLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setSectionAddError(errorMsg);
        } else {
          setSectionAddError([err.response.data.message]);
        }
    });
  }

  const handleEditClose = () => {
    setSectionEditError(null);
    setSectionId(null);
    setSectionName("");
    setSectionInst("");
    setSectionClass([]);
    setShowEditDefault(false);
  }

  const showEditForm = (data) => {
    setSectionId(data.id);    
    setSectionName(data.name);
    setSectionInst(data.institute_id);
    setSectionCity(data.city_id);
    getInst(data.city_id);
    getCls(data.institute_id);
    // api call
    http.post(Apis.section.get_section_classes, {section_uuid: data.id})
    .then(res => {
      const classIds = res.data.data.map(obj => obj.class_id);        
      setSectionClass(classIds);
    });
    setShowEditDefault(true);
  }

  const updateSection = (e) => {
    e.preventDefault();
    setSectionEditError(null);
    setSectionEditLoading(true);    
    // api call
    http.post(Apis.section.update, {section_uuid: sectionId, name: sectionName, inst_uuid: sectionInst, cls_uuid: sectionClass})
    .then(res => {
        setSectionEditLoading(false);
        setSectionEditSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setSectionEditLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setSectionEditError(errorMsg);
        } else {
          setSectionEditError([err.response.data.message]);
        }
    });
  }
  
  const deleteSection = (sectionId) => {
    const resp = window.confirm("Do you want to delete this section?");
    if (resp) {
      setSectionDeleteError(null);
      setSectionDeleteLoading(true);
      http.post(Apis.section.delete, {section_uuid: sectionId})
      .then(res => {
        setSectionDeleteLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setSectionDeleteLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setSectionDeleteError(errorMsg);
        } else {
          setSectionDeleteError([err.response.data.message]);
        }
      });
    }
  }
  
  const goToPhotoUploads = (cityId, instId, classId, taggedClassIds, sectionId, productType) => {
    sessionStorage.setItem("taggedClassIds", JSON.stringify(taggedClassIds));
    history.push(`${Routes.PhotoUploads.path.slice(0, 14)}/${productType}/${cityId}/${instId}/${classId}/${sectionId}`);
  }

  useEffect(() => {
    // api call
    http.get(Apis.cities)
    .then(res => {        
        setCities(res.data.data);
    });
  }, []);

  const getInst = (cityId) => {
    // api call
    http.get(`${Apis.inst_by_city}/${cityId}`)
    .then(res => {        
        setInstByCity(res.data.data);
    });
  }

  const getCls = (instId) => {
    setSectionInst(instId);
    // api call
    http.get(`${Apis.cls_by_inst}/${instId}`)
    .then(res => {        
        setClsByInst(res.data.data);
    });
  }

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.section.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText) {
      apiString += `&search_keyword=${searchText}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        setData(res.data.data.data);
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 mt-3">
            <h5>Section Management</h5>
          </div>
          <div className="col-sm-6 text-right mt-2">
            <button className="btn cust-button me-2" onClick={showAddForm}>+</button>
          </div>
        </div>
        {
            error && <ErrorMsg errors={error} />
        }
        {
            sectionDeleteError && <ErrorMsg errors={sectionDeleteError} />
        }
        {
            sectionDeleteLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Add Section</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {
              sectionAddSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {sectionAddSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              sectionAddError && <ErrorMsg errors={sectionAddError} />
          }
          <Form className="mt-4" onSubmit={ saveSection }>

            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" onChange={(e) => setSectionName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select onChange={(e) => getInst(e.target.value)}>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Institute<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select onChange={(e) => getCls(e.target.value)}>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                  return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Class<span style={{ color: "red" }}> *</span></Form.Label>
                <Form.Select multiple onChange={(e) => setClassesToState(e)}>
                    <option defaultValue value="">Select class...</option>
                    {
                      clsByInst && clsByInst.map((cls, i) => {
                                      return <option key={i} value={cls.id}>{cls.name}</option>
                                    })
                    }
                </Form.Select>
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={sectionAddLoading}>
                Save{sectionAddLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showEditDefault} onHide={handleEditClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Edit Section</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleEditClose} />
        </Modal.Header>
        <Modal.Body>
          {
              sectionEditSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {sectionEditSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              sectionEditError && <ErrorMsg errors={sectionEditError} />
          }
          <Form className="mt-4" onSubmit={ updateSection }>

            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" value={sectionName} onChange={(e) => setSectionName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select value={sectionCity} disabled>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Institute</Form.Label>
              <Form.Select value={sectionInst} disabled>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                  return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Class<span style={{ color: "red" }}> *</span></Form.Label>
                <Form.Select multiple onChange={(e) => setClassesToState(e)}>
                    <option defaultValue value="">Select class...</option>
                    {
                      clsByInst && clsByInst.map((cls, i) => {
                                      return <option key={i} value={cls.id} selected={sectionClass.includes(cls.id) ? true : false}>{cls.name}</option>
                                    })
                    }
                </Form.Select>
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={sectionEditLoading}>
                Update{sectionEditLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Sections;