
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSignOutAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container, ListGroup, Form } from '@themesberg/react-bootstrap';

import AuthUser from './AuthUser';
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import { Apis } from "../common/Apis";

export default (props) => {
  const history = useHistory();

  const {token, user, logout, http} = AuthUser();
  const [AYs, setAYs] = useState(null);

  const handleLogout = () => {
      if(token !== undefined){
          logout();
      }
  }

  const goBack = () => {
    history.goBack();
  }

  useEffect(() => {
    // api call
    http.get(Apis.get_academic_years)
    .then(res => {        
        setAYs(res.data.data.academic_years);
        if (sessionStorage.getItem("academic_year"))
          sessionStorage.setItem("academic_year", sessionStorage.getItem("academic_year"));
        else
          sessionStorage.setItem("academic_year", res.data.data.academic_years[0]);
    });
  }, []);

  const updateAY = (AY) => {
    sessionStorage.setItem("academic_year", AY);
    window.location.reload();
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 cust-navbar">
      <Container fluid className="px-0">
        {
          !window.location.href.includes(Routes.DashboardOverview.path) &&
            <button className="btn" onClick={ goBack }>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
        }
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center"></div>
          <Nav className="align-items-center">            
            <Dropdown as={Nav.Item}>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Form className="me-4">
              <Form.Select onChange={(e) => updateAY(e.target.value)}>
                {
                  AYs && AYs.map((ay, i) => {
                              return <option key={i} selected={ ay === sessionStorage.getItem("academic_year") || false } value={ay}>AY: {ay}</option>
                            })
                }                
              </Form.Select>
            </Form>
            
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={ user.profile_photo ? user.profile_photo : `${process.env.PUBLIC_URL}/default_user.jpg` } className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{ user ? user.name : '' }</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Link to={Routes.ChangePassword.path} className="fw-bold dropdown-item">
                  <FontAwesomeIcon icon={faKey} className="me-2" /> Change Password
                  </Link>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold">
                  <span role="button" onClick={ handleLogout }>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
