import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Table from "../Table";
import { Form, Button, Modal } from '@themesberg/react-bootstrap';
import AuthUser from "../components/AuthUser";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBriefcase, faChalkboard, faChalkboardTeacher, faGraduationCap, faImages, faPencilAlt, faTrashAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";

const Institutes = () => {
  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isSorted: true
      },
      {
        Header: "City",
        accessor: "city_name",
        isSorted: true
      },
      {
        Header: "Branch",
        accessor: "branch_name",
        isSorted: true
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer' }}>
              <div className="d-inline-block me-1">In-Active</div>
              <div className="form-check form-switch d-inline-block">
                <input type="checkbox" className="form-check-input" id={"status_" + original.id} defaultChecked={ original.status === 'ACTIVE' ? true : false } onChange={ (e) => toggleStatus(original.id, e) } style={{ cursor: 'pointer' }} />
                <label htmlFor={"status_" + original.id} className="form-check-label">Active</label>
              </div>
            </span>            
          </>            
        ),
        isSorted: true
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer' }} onClick={ () => showEditForm(original) }><FontAwesomeIcon icon={ faPencilAlt } /></span>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Upload Class" onClick={ () => showBIModal("class", original.id) }><FontAwesomeIcon icon={ faChalkboard } /></button>            
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Upload Section" onClick={ () => showBIModal("section", original.id) }><FontAwesomeIcon icon={ faChalkboardTeacher } /></button>            
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Upload Student" onClick={ () => showBIModal("student", original.id) }><FontAwesomeIcon icon={ faGraduationCap } /></button>            
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Upload Staff" onClick={ () => showBIModal("staff", original.id) }><FontAwesomeIcon icon={ faBriefcase } /></button>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Upload Promotional Images" onClick={ () => showBIModal("promotion", original.id, original.promo_images) }><FontAwesomeIcon icon={ faUpload } /></button>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Composite Albums" onClick={ () => goToPhotoUploads(original.city_id, original.id, "CompositePhoto") }><FontAwesomeIcon icon={ faImages } /></button>
            <button className="btn btn-small cust-button ms-2" style={{ borderRadius: "50%" }} title="Memory Books" onClick={ () => goToPhotoUploads(original.city_id, original.id, "MemoryBook") }><FontAwesomeIcon icon={ faBook } /></button>            
          </>            
        )
      }
    ],
    []
  );

  const history = useHistory();
  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showDefault, setShowDefault] = useState(false);
  const [cities, setCities] = useState(null);
  const [instName, setInstName] = useState("");
  const [instCity, setInstCity] = useState("");
  const [instBranch, setInstBranch] = useState("");
  const [instStatus, setInstStatus] = useState("ACTIVE");
  const [instAddLoading, setInstAddLoading] = useState(false);
  const [instAddSuccess, setInstAddSuccess] = useState(null);
  const [instAddError, setInstAddError] = useState(null);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [instId, setInstId] = useState(null);
  const [instEditLoading, setInstEditLoading] = useState(false);
  const [instEditSuccess, setInstEditSuccess] = useState(null);
  const [instEditError, setInstEditError] = useState(null);
  const [instToggleLoading, setInstToggleLoading] = useState(false);
  const [instToggleError, setInstToggleError] = useState(null);
  const [showBIDefault, setShowBIDefault] = useState(false);
  const [moduleType, setModuleType] = useState(null);
  const [file, setFile] = useState(null);
  const [promoImages, setPromoImages] = useState(null);
  const [promoImageId, setPromoImageId] = useState(null);
  const [promoInstId, setPromoInstId] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [uploadFailedRows, setUploadFailedRows] = useState(null);
  const [promoImageRemoveLoading, setPromoImageRemoveLoading] = useState(false);
  const [promoImageRemoveError, setPromoImageRemoveError] = useState(null);
  const [showUploadPromoImageDefault, setShowUploadPromoImageDefault] = useState(false);
  const [promoImageChangeLoading, setPromoImageChangeLoading] = useState(false);
  const [promoImageChangeSuccess, setPromoImageChangeSuccess] = useState(null);
  const [promoImageChangeError, setPromoImageChangeError] = useState(null);

  const handleClose = () => {
    setInstAddError(null);
    setShowDefault(false);
  }
  const showAddForm = () => {
    setShowDefault(true);
  }

  const saveInst = (e) => {
    e.preventDefault();
    setInstAddError(null);
    setInstAddLoading(true);    
    // api call
    http.post(Apis.institute.save, {name: instName, city_uuid: instCity, branch_name: instBranch, status: instStatus})
    .then(res => {
        setInstAddLoading(false);
        setInstAddSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setInstAddLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setInstAddError(errorMsg);
        } else {
          setInstAddError([err.response.data.message]);
        }
    });
  }

  const handleEditClose = () => {
    setInstEditError(null);
    setInstId(null);
    setInstName("");
    setInstCity("");
    setInstBranch("");
    setInstStatus("ACTIVE");
    setShowEditDefault(false);
  }

  const showEditForm = (data) => {
    setInstId(data.id);    
    setInstName(data.name);
    setInstCity(data.city_id);
    setInstBranch(data.branch_name);
    setInstStatus(data.status);
    setShowEditDefault(true);
  }

  const updateInst = (e) => {
    e.preventDefault();
    setInstEditError(null);
    setInstEditLoading(true);    
    // api call
    http.post(Apis.institute.update, {inst_uuid: instId, name: instName, city_uuid: instCity, branch_name: instBranch, status: instStatus})
    .then(res => {
        setInstEditLoading(false);
        setInstEditSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setInstEditLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setInstEditError(errorMsg);
        } else {
          setInstEditError([err.response.data.message]);
        }
    });
  }

  const toggleStatus = (instId, e) => {
    e.persist();
    const status = e.target.checked ? "ACTIVE" : "INACTIVE";
    setInstToggleError(null);
    setInstToggleLoading(true);    
    // api call
    http.post(Apis.institute.toggleStatus, {inst_uuid: instId, status: status})
    .then(res => {
        setInstToggleLoading(false);        
    })
    .catch(err => {
        document.getElementById(e.target.id).checked = !e.target.checked;
        setInstToggleLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setInstToggleError(errorMsg);
        } else {
          setInstToggleError([err.response.data.message]);
        }
    });
  }  

  const handleBIClose = () => {
    setUploadError(null);
    setPromoImageRemoveError(null);
    setUploadSuccess(null);
    setModuleType(null);
    setInstId(null);
    setFile(null);
    setShowBIDefault(false);
  }

  const showBIModal = (moduleType, instId, promoImages = null) => {
    setModuleType(moduleType);
    setInstId(instId);
    setPromoImages(promoImages);
    setShowBIDefault(true);
  }

  const handleUpdatePromoImageClose = () => {
    setPromoImageChangeError(null);
    setPromoImageChangeSuccess(null);
    setPromoImageId(null);
    setPromoInstId(null);
    setFile(null);
    setShowUploadPromoImageDefault(false);
  }
  const showUpdatePromoImageModal = (imgId, instId) => {
    setPromoImageId(imgId);
    setPromoInstId(instId);
    setShowUploadPromoImageDefault(true);
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const setFileToState = (e) => {
    setFile(e.target.files[0]);
  }

  const setFilesToState = (e) => {
    setFile(e.target.files);
  }

  const uploadFile = (e) => {
    e.preventDefault();
    setUploadError(null);
    setUploadFailedRows(null);
    setUploadLoading(true);
    
    const formData = new FormData();
    formData.append('bulk_file', file);
    formData.append('bulk_type', moduleType);
    formData.append('inst_uuid', instId);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    
    http.post(Apis.bulk_upload, formData, config)
    .then(res => {
        setUploadLoading(false);
        if (res.data.data.success_count > 0) {
          setUploadSuccess(`${res.data.message} Inserted rows - ${res.data.data.success_count}`);
        }
        if (res.data.data.fail_count > 0) {
          setUploadFailedRows(res.data.data.fail_count);
          setUploadError(res.data.data.fail_arr);
        }
    })
    .catch(err => {
        setUploadLoading(false);
        if (err.response.status === 400) {
            const errorMsg = parseError(err.response.data.error.bulk_file);
            setUploadError(errorMsg);
        } else {
            setUploadError([err.response.data.message]);
        }
    });
  }

  const updatePromoImage = (e) => {
    e.preventDefault();
    setPromoImageChangeError(null);
    setPromoImageChangeLoading(true);

    const formData = new FormData();
    formData.append('files[]', file);
    formData.append('image_id', promoImageId);
    formData.append('inst_uuid', promoInstId);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };

    http.post(Apis.replace_promotion_image, formData, config)
    .then(res => {
      setPromoImageChangeLoading(false);
      setPromoImageChangeSuccess(res.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 800);
    })
    .catch(err => {
      setPromoImageChangeLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setPromoImageChangeError(errorMsg);
      } else {
        setPromoImageChangeError([err.response.data.message]);
      }
    });
  }

  const removePromoImage = (id) => {
    const resp = window.confirm("Do you want to remove this image?");
    if (resp) {
      setPromoImageRemoveError(null);
      setPromoImageRemoveLoading(true);
      http.post(Apis.delete_promotion_images, {image_id: id})
      .then(res => {
        setPromoImageRemoveLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(err => {
        setPromoImageRemoveLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setPromoImageRemoveError(errorMsg);
        } else {
          setPromoImageRemoveError([err.response.data.message]);
        }
      });
    }
  }

  const uploadFiles = (e) => {
    e.preventDefault();
    setUploadError(null);
    setUploadLoading(true);
    
    const formData = new FormData();
    if (file) {
      for (let i = 0 ; i < file.length ; i++) {
        formData.append("files[]", file[i]);
      }
    } else {
      formData.append("files[]", file);
    }
    formData.append('inst_uuid', instId);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    
    http.post(Apis.add_promotion_images, formData, config)
    .then(res => {
        setUploadLoading(false);
        setUploadSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);
    })
    .catch(err => {
        setUploadLoading(false);
        if (err.response.status === 400) {
            const errorMsg = parseError(err.response.data.error);
            setUploadError(errorMsg);
        } else {
            setUploadError([err.response.data.message]);
        }
    });
  }

  const goToPhotoUploads = (cityId, instId, productType) => {
    history.push(`${Routes.PhotoUploads.path.slice(0, 14)}/${productType}/${cityId}/${instId}`);
  }

  useEffect(() => {
    // api call
    http.get(Apis.cities)
    .then(res => {        
        setCities(res.data.data);
    });
  }, []);

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.institute.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText) {
      apiString += `&search_keyword=${searchText}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        setData(res.data.data.data);
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 mt-3">
            <h5>Institution Management</h5>
          </div>
          <div className="col-sm-6 text-right mt-2">
            <button className="btn cust-button" onClick={showAddForm}>+</button>
          </div>
        </div>
        {
            error && <ErrorMsg errors={error} />
        }
        {
            instToggleError && <ErrorMsg errors={instToggleError} />
        }
        {
            instToggleLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Add Institute</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {
              instAddSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {instAddSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              instAddError && <ErrorMsg errors={instAddError} />
          }
          <Form className="mt-4" onSubmit={ saveInst }>
            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" onChange={(e) => setInstName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select onChange={(e) => setInstCity(e.target.value)}>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>

            <Form.Group id="branch" className="mb-4">
              <Form.Label>Branch</Form.Label>
              <Form.Control placeholder="Enter Branch" onChange={(e) => setInstBranch(e.target.value)} />
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={instAddLoading}>
                Save{instAddLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showEditDefault} onHide={handleEditClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Edit Institute</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleEditClose} />
        </Modal.Header>
        <Modal.Body>
          {
              instEditSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {instEditSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              instEditError && <ErrorMsg errors={instEditError} />
          }
          <Form className="mt-4" onSubmit={ updateInst }>
            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" value={instName} onChange={(e) => setInstName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select value={instCity} onChange={(e) => setInstCity(e.target.value)}>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group id="branch" className="mb-4">
              <Form.Label>Branch</Form.Label>
              <Form.Control placeholder="Enter Branch" value={instBranch} onChange={(e) => setInstBranch(e.target.value)} />
            </Form.Group>

            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={instEditLoading}>
                Update{instEditLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showBIDefault} onHide={handleBIClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
            <Modal.Title className="h6">Upload { moduleType && (moduleType === "promotion" ? "Promotional Images" : capitalizeFirstLetter(moduleType)) }</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleBIClose} />
        </Modal.Header>
        <Modal.Body>
            {
                uploadSuccess && <div className="row mt-3">
                                    <div className="col">
                                        <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                            <strong>Success!</strong> {uploadSuccess}
                                        </div>
                                    </div>
                                </div>
            }
            {
                uploadError && <ErrorMsg errors={uploadError} failedRows={uploadFailedRows} />
            }
            {
                promoImageRemoveError && <ErrorMsg errors={promoImageRemoveError} />
            }
            {
                promoImageRemoveLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
            }
            <Form className="mt-4" onSubmit={ moduleType === "promotion" ? uploadFiles : uploadFile }>
                <Form.Group id="file" className="mb-4">
                    <Form.Control type="file" multiple={ moduleType === "promotion" || false } accept={ moduleType === "promotion" ? ".jpeg, .jpg, .png, .webp" : ".csv, .xls, .xlsx" } onChange={ moduleType === "promotion" ? setFilesToState : setFileToState } />
                    { moduleType === "promotion" ? <small>Supported file types: Image (JPEG, JPG, PNG, WEBP), 10 files max</small> : <small>Supported file types: XLSX, XLS & CSV</small> }
                </Form.Group>                
                <div className="text-center">
                    <button type="submit" className="btn cust-button w-50" disabled={uploadLoading}>
                        Upload{uploadLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                    </button>
                </div>
                <div className="row mt-3">
                  {
                    promoImages && promoImages.map((image, i) => {
                      return (
                        <div key={i} className="col-sm-6">
                          <img src={image.photo_url} alt="image" className="img-thumbnail" style={{width: "200px", height: "200px"}} />
                          <span className="text-success" style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={ () => showUpdatePromoImageModal(image.id, image.institute_id) }><FontAwesomeIcon title="Change" icon={ faPencilAlt } /></span>
                          <span className="text-danger" style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={ () => removePromoImage(image.id) }><FontAwesomeIcon title="Remove" icon={ faTrashAlt } /></span>
                        </div>
                      );
                    })
                  }
                </div>
            </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showUploadPromoImageDefault} onHide={handleUpdatePromoImageClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
            <Modal.Title className="h6">Update Promotional Image</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleUpdatePromoImageClose} />
        </Modal.Header>
        <Modal.Body>
            {
                promoImageChangeSuccess && <div className="row mt-3">
                                    <div className="col">
                                        <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                            <strong>Success!</strong> {promoImageChangeSuccess}
                                        </div>
                                    </div>
                                </div>
            }
            {
                promoImageChangeError && <ErrorMsg errors={promoImageChangeError} />
            }
            <Form className="mt-4" onSubmit={ updatePromoImage }>
                <Form.Group id="file" className="mb-4">
                    <Form.Control type="file" accept=".jpeg, .jpg, .png, .webp" onChange={ setFileToState } />
                    <small>Supported file types: Image (JPEG, JPG, PNG, WEBP)</small>
                </Form.Group>                
                <div className="text-center">
                    <button type="submit" className="btn cust-button w-50" disabled={promoImageChangeLoading}>
                        Upload {promoImageChangeLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
                    </button>
                </div>
            </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Institutes;