
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Container } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import AuthUser from "../../components/AuthUser";

export default () => {
  const { user } = AuthUser();
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <h1 className="text-primary mt-5">
                  Access <span className="fw-bolder">Forbidden</span>
                </h1>
                <Button as={Link} variant="primary" className="animate-hover" to={ user ? Routes.DashboardOverview.path : Routes.Signin.path }>
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
