import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Table from "../Table";
import { Form, Button, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AuthUser from "../components/AuthUser";
import { Apis } from "../common/Apis";
import { parseError } from "../common/Utility";
import ErrorMsg from "../common/ErrorMsg";
import { Routes } from "../routes";

const Staffs = () => {
  const { http, getToken } = AuthUser();
  if(!getToken()){
    window.location.href = Routes.Signin.path;
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isSorted: true
      },
      {
        Header: "Institute",
        accessor: "institute_name",
        isSorted: true
      },
      {
        Header: "Mobile",
        accessor: "mobile"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Profile Image",
        accessor: "profile_photo",
        Cell: ({ row: { original } }) => (
          <>
            { original.profile_photo && <img src={original.profile_photo} width="50" alt="profile image" /> }            
          </>            
        )
      },
      {
        Header: "Staff Type",
        accessor: "type",
        isSorted: true
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer' }}>
              <div className="d-inline-block me-1">In-Active</div>
              <div className="form-check form-switch d-inline-block">
                <input type="checkbox" className="form-check-input" id={"status_" + original.id} defaultChecked={ original.status === 'ACTIVE' ? true : false } onChange={ (e) => toggleStatus(original.id, e) } style={{ cursor: 'pointer' }} />
                <label htmlFor={"status_" + original.id} className="form-check-label">Active</label>
              </div>
            </span>            
          </>            
        ),
        isSorted: true
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <>
            <span style={{ cursor: 'pointer' }} onClick={ () => showEditForm(original) }><FontAwesomeIcon icon={ faPencilAlt } /></span>            
          </>            
        )
      }
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [showDefault, setShowDefault] = useState(false);
  const [cities, setCities] = useState(null);
  const [roles, setRoles] = useState(null);
  const [instByCity, setInstByCity] = useState(null);
  const [clsByInst, setClsByInst] = useState(null);
  const [staffId, setStaffId] = useState(null);
  const [staffName, setStaffName] = useState("");
  const [staffCity, setStaffCity] = useState("");
  const [staffInst, setStaffInst] = useState("");
  const [staffMobile, setStaffMobile] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [staffProfilePic, setStaffProfilePic] = useState(null);
  const [image, setImage] = useState(null);
  const [staffClass, setStaffClass] = useState([]);
  const [staffType, setStaffType] = useState("");
  const [staffStatus, setStaffStatus] = useState("ACTIVE");
  const [userRole, setUserRole] = useState([]);
  const [staffAddLoading, setStaffAddLoading] = useState(false);
  const [staffAddSuccess, setStaffAddSuccess] = useState(null);
  const [staffAddError, setStaffAddError] = useState(null);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [staffEditLoading, setStaffEditLoading] = useState(false);
  const [staffEditSuccess, setStaffEditSuccess] = useState(null);
  const [staffEditError, setStaffEditError] = useState(null);
  const [staffToggleLoading, setStaffToggleLoading] = useState(false);
  const [staffToggleError, setStaffToggleError] = useState(null);

  const handleClose = () => {
    setStaffAddError(null);
    setShowDefault(false);
  }
  const showAddForm = () => {
    setShowDefault(true);
  }

  const setClassesToState = (e) => {
    const options = e.target.options;
    let value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setStaffClass(value);
  }

  const selectAllClasses = () => {
    const options = document.getElementById("classes").options;
    let values = [];
    if (staffClass.length === 0) {
      for (let i = 1, l = options.length; i < l; i++) {
        options[i].selected = true;
        values.push(options[i].value);
      }
    } else {
      for (let i = 1, l = options.length; i < l; i++) {
        options[i].selected = false;
      }
    }
    setStaffClass(values);
  }

  const setImageToState = (e) => {
    setImage(e.target.files[0]);
  }

  const setRolesToState = (e) => {
    if (e.target.checked) {
      setUserRole([...userRole, e.target.value]);
    } else {
      setUserRole(userRole.filter(rId => rId !== e.target.value));
    }
  }

  const saveStaff = (e) => {
    e.preventDefault();
    setStaffAddError(null);
    setStaffAddLoading(true);    
    // api call
    http.post(Apis.staff.save, {name: staffName, inst_uuid: staffInst, mobile: staffMobile, email: staffEmail, type: staffType, class_uuids: staffClass, role_uuids: userRole, status: staffStatus})
    .then(res => {
        if (image) {
          const formData = new FormData();
          formData.append('files[]', image);
          formData.append('type', 'profile_photo');
          formData.append('user_uuid', res.data.data.created_user);
          const staffId = res.data.data.created_user;
          const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
          };
          
          http.post(Apis.upload_file, formData, config)
          .then(res => {
            http.post(Apis.staff.update, {user_uuid: staffId, name: staffName, inst_uuid: staffInst, mobile: staffMobile, email: staffEmail, profile_photo: res.data.data.files[0].url, type: staffType, class_uuids: staffClass, role_uuids: userRole, status: staffStatus})
            .then(res => {
              setStaffAddLoading(false);
              setStaffAddSuccess("User added successfully!");
              setTimeout(() => {
                window.location.reload();
              }, 800);
            })
            .catch(err => {
              setStaffAddLoading(false);
              if (err.response.status === 400) {
                const errorMsg = parseError(err.response.data.error);
                setStaffAddError(errorMsg);
              } else {
                setStaffAddError([err.response.data.message]);
              }
            });
          })
          .catch(err => {
            setStaffAddLoading(false);
            if (err.response.status === 400) {
              const errorMsg = parseError(err.response.data.error);
              setStaffAddError(errorMsg);
            } else {
              setStaffAddError([err.response.data.message]);
            }
          });
        } else {
          setStaffAddLoading(false);
          setStaffAddSuccess(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 800);
        }                
    })
    .catch(err => {
        setStaffAddLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setStaffAddError(errorMsg);
        } else {
          setStaffAddError([err.response.data.message]);
        }
    });
  }

  const handleEditClose = () => {
    setStaffEditError(null);
    setStaffId(null);
    setStaffName("");
    setStaffCity("");
    setStaffInst("");
    setStaffMobile("");
    setStaffEmail("");
    setStaffProfilePic(null);
    setStaffClass([]);
    setStaffType("");
    setUserRole([]);
    setStaffStatus("ACTIVE");
    setShowEditDefault(false);
  }

  const showEditForm = (data) => {
    setStaffId(data.id);    
    setStaffName(data.name);
    setStaffCity(data.city_id);
    setStaffMobile(data.mobile);
    setStaffEmail(data.email);
    setStaffProfilePic(data.profile_photo);
    setStaffType(data.type);
    const roleIds = data.user_roles.map(obj => obj.id);
    setUserRole(roleIds);
    setStaffStatus(data.status);
    getInst(data.city_id);
    getCls(data.institute_id);
    const classIds = data.user_classes.map(obj => obj.class_id);
    setStaffClass(classIds);
    setShowEditDefault(true);
  }

  const updateStaff = (e) => {
    e.preventDefault();
    setStaffEditError(null);
    setStaffEditLoading(true);
    if (image) {
      const formData = new FormData();
      formData.append('files[]', image);
      formData.append('type', 'profile_photo');
      formData.append('user_uuid', staffId);
      const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
      };
      
      http.post(Apis.upload_file, formData, config)
      .then(res => {
        update(res.data.data.files[0].url);
      })
      .catch(err => {
        setStaffEditLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setStaffEditError(errorMsg);
        } else {
          setStaffEditError([err.response.data.message]);
        }
      });    
    } else {
      update();
    }
  }

  const update = (profilePhoto = null) => {
    const jsonData = {user_uuid: staffId, name: staffName, inst_uuid: staffInst, mobile: staffMobile, email: staffEmail, type: staffType, class_uuids: staffClass, role_uuids: userRole, status: staffStatus};
    if (profilePhoto) {
      jsonData.profile_photo = profilePhoto;
    }
    // api call
    http.post(Apis.staff.update, jsonData)
    .then(res => {
        setStaffEditLoading(false);
        setStaffEditSuccess(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 800);        
    })
    .catch(err => {
        setStaffEditLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setStaffEditError(errorMsg);
        } else {
          setStaffEditError([err.response.data.message]);
        }
    });
  }

  const toggleStatus = (staffId, e) => {
    e.persist();
    const status = e.target.checked ? "ACTIVE" : "INACTIVE";
    setStaffToggleError(null);
    setStaffToggleLoading(true);    
    // api call
    http.post(Apis.staff.toggleStatus, {user_uuid: staffId, status: status})
    .then(res => {
        setStaffToggleLoading(false);        
    })
    .catch(err => {
        document.getElementById(e.target.id).checked = !e.target.checked;
        setStaffToggleLoading(false);
        if (err.response.status === 400) {
          const errorMsg = parseError(err.response.data.error);
          setStaffToggleError(errorMsg);
        } else {
          setStaffToggleError([err.response.data.message]);
        }
    });
  }

  useEffect(() => {
    // api call
    http.get(Apis.cities)
    .then(res => {        
        setCities(res.data.data);
    });

    http.get(Apis.get_roles)
    .then(res => {        
        setRoles(res.data.data);
    });
  }, []);

  const getInst = (cityId) => {
    setStaffInst("");
    setStaffClass([]);
    // api call
    http.get(`${Apis.inst_by_city}/${cityId}`)
    .then(res => {        
        setInstByCity(res.data.data);
    });
  }

  const getCls = (instId) => {
    setStaffInst(instId);
    // api call
    http.get(`${Apis.cls_by_inst}/${instId}`)
    .then(res => {        
        setClsByInst(res.data.data);
    });
  }

  /* This will get called when the table needs new data */
  const fetchData = useCallback(({ pageSize, pageIndex, searchText, colName, sortOrder }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    setError(null);
    // Set the loading state
    setLoading(true);
    // api call
    let apiString = `${Apis.staff.list}?per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText) {
      apiString += `&search_keyword=${searchText}`;
    }
    if (colName && sortOrder) {
      apiString += `&sort_column=${colName}&sort_order=${sortOrder}`;
    }
    http.get(apiString)
    .then(res => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {        
        setData(res.data.data.data);
        setPageCount(res.data.data.last_page);
        setLoading(false);
      }
    })
    .catch(err => {
      setLoading(false);
      if (err.response.status === 400) {
        const errorMsg = parseError(err.response.data.error);
        setError(errorMsg);
      } else {
        setError([err.response.data.message]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 mt-3">
            <h5>Staff Management</h5>
          </div>
          <div className="col-sm-6 text-right mt-2">
            <button className="btn cust-button me-2" onClick={showAddForm}>+</button>
          </div>
        </div>
        {
            error && <ErrorMsg errors={error} />
        }
        {
            staffToggleError && <ErrorMsg errors={staffToggleError} />
        }
        {
            staffToggleLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', marginTop: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>
        }
        {/* Server side table component */}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
        {/* Server side table component */}
      </div>      

      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Add Staff</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {
              staffAddSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {staffAddSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              staffAddError && <ErrorMsg errors={staffAddError} />
          }
          <Form className="mt-4" onSubmit={ saveStaff }>
            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" onChange={(e) => setStaffName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select onChange={(e) => getInst(e.target.value)}>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Institute<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Select value={staffInst} onChange={(e) => getCls(e.target.value)}>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                  return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                })
                }
              </Form.Select>
            </Form.Group>
            <Form.Group id="mobile" className="mb-4">
              <Form.Label>Mobile<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Mobile" onChange={(e) => setStaffMobile(e.target.value)} />
            </Form.Group>
            <Form.Group id="email" className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter Email" onChange={(e) => setStaffEmail(e.target.value)} />
            </Form.Group>
            <Form.Group id="profileimage" className="mb-4">
              <Form.Label>Profile Image</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={ setImageToState } />
            </Form.Group>
            <Form.Group id="class" className="mb-4">
              <div className="row">
                <div className="col-sm-6">
                  <Form.Label>Classes<span style={{ color: "red" }}> *</span></Form.Label>
                </div>
                <div className="col-sm-6 text-right">
                  <a onClick={ selectAllClasses }>Select All</a>
                </div>
              </div>
              <Form.Select id="classes" multiple value={staffClass} onChange={(e) => setClassesToState(e)}>
                <option defaultValue value="">Select classes...</option>
                {
                  clsByInst && clsByInst.map((cls, i) => {
                                      return <option key={i} value={cls.id}>{cls.name}</option>
                                    })
                }
              </Form.Select>
            </Form.Group>
            <Form.Group id="stafftype" className="mb-4">
              <Form.Label>Staff Type</Form.Label>
              <Form.Select onChange={(e) => setStaffType(e.target.value)}>
                <option defaultValue value="">Select staff type...</option>
                <option value="Teaching">Teaching</option>
                <option value="NonTeaching">Non Teaching</option>
                <option value="Management">Management</option>
              </Form.Select>
            </Form.Group>
            <Form.Group id="roles" className="mb-4">
              <Form.Label>Roles</Form.Label>
              <fieldset>
                {
                  roles && roles.map((role, i) => {
                              return <Form.Check key={i} className="d-inline-block me-1" label={role.name} value={role.id} onChange={(e) => setRolesToState(e)} />
                            })
                }
              </fieldset>
            </Form.Group>
            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={staffAddLoading}>
                Save{staffAddLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} centered show={showEditDefault} onHide={handleEditClose}>
        <Modal.Header style={{ borderBottom: '1px solid #cccccc' }}>
          <Modal.Title className="h6">Edit Staff</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleEditClose} />
        </Modal.Header>
        <Modal.Body>
          {
              staffEditSuccess && <div className="row mt-3">
                                  <div className="col">
                                      <div className="alert alert-success" style={{padding: '.5rem 1rem'}}>
                                          <strong>Success!</strong> {staffEditSuccess}
                                      </div>
                                  </div>
                              </div>
          }
          {
              staffEditError && <ErrorMsg errors={staffEditError} />
          }
          <Form className="mt-4" onSubmit={ updateStaff }>
            <Form.Group id="name" className="mb-4">
              <Form.Label>Name<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Name" value={staffName} onChange={(e) => setStaffName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select value={staffCity} disabled>
                <option defaultValue value="">Select city...</option>
                {
                  cities && cities.map((city, i) => {
                              return <option key={i} value={city.id}>{city.name}</option>
                            })
                }                
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Institute</Form.Label>
              <Form.Select value={staffInst} disabled>
                <option defaultValue value="">Select institute...</option>
                {
                  instByCity && instByCity.map((inst, i) => {
                                  return <option key={i} value={inst.id}>{inst.name}{inst.branch_name && ` (${inst.branch_name})`}</option>
                                })
                }
              </Form.Select>
            </Form.Group>
            <Form.Group id="mobile" className="mb-4">
              <Form.Label>Mobile<span style={{ color: "red" }}> *</span></Form.Label>
              <Form.Control required placeholder="Enter Mobile" value={staffMobile} onChange={(e) => setStaffMobile(e.target.value)} />
            </Form.Group>
            <Form.Group id="email" className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter Email" value={staffEmail} onChange={(e) => setStaffEmail(e.target.value)} />
            </Form.Group>
            <Form.Group id="profileimage" className="mb-4">
              <Form.Label>Profile Image</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={ setImageToState } />
              { staffProfilePic && <img src={staffProfilePic} width="50" alt="profile image" /> }
            </Form.Group>
            <Form.Group id="class" className="mb-4">
              <div className="row">
                <div className="col-sm-6">
                  <Form.Label>Classes<span style={{ color: "red" }}> *</span></Form.Label>
                </div>
                <div className="col-sm-6 text-right">
                  <a onClick={ selectAllClasses }>Select All</a>
                </div>
              </div>
              <Form.Select id="classes" multiple onChange={(e) => setClassesToState(e)}>
                <option defaultValue value="">Select classes...</option>
                {
                  clsByInst && clsByInst.map((cls, i) => {
                                      return <option key={i} value={cls.id} selected={staffClass.includes(cls.id) ? true : false}>{cls.name}</option>
                                    })
                }
              </Form.Select>
            </Form.Group>
            <Form.Group id="stafftype" className="mb-4">
              <Form.Label>Staff Type</Form.Label>
              <Form.Select value={staffType} onChange={(e) => setStaffType(e.target.value)}>
                <option defaultValue value="">Select staff type...</option>
                <option value="Teaching">Teaching</option>
                <option value="NonTeaching">Non Teaching</option>
                <option value="Management">Management</option>
              </Form.Select>
            </Form.Group>
            <Form.Group id="roles" className="mb-4">
              <Form.Label>Roles</Form.Label>
              <fieldset>
                {
                  roles && roles.map((role, i) => {
                              return <Form.Check key={i} className="d-inline-block me-1" label={role.name} value={role.id} defaultChecked={userRole.includes(role.id) ? true : false} onChange={(e) => setRolesToState(e)} />
                            })
                }
              </fieldset>
            </Form.Group>
            <div className="text-center">
              <button type="submit" className="btn cust-button w-50" disabled={staffEditLoading}>
                Update{staffEditLoading && <span className="spinner-border spinner-border-sm" style={{marginLeft: '5px', width: '1rem', height: '1rem', verticalAlign: 'middle', animation: '1s linear infinite spinner-border'}}></span>}
              </button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Staffs;