
export const Routes = {
    // App Routes
    Home: { path: "/" },
    Signin: { path: "/login" },
    ChangePassword: { path: "/change-password" },
    // ForgotPassword: { path: "/examples/forgot-password" },
    // ResetPassword: { path: "/examples/reset-password" },
    DashboardOverview: { path: "/dashboard/overview" },
    Cities: { path: "/cities" },
    Institutes: { path: "/institutes" },
    Cls: { path: "/classes" },
    Sections: { path: "/sections" },
    Students: { path: "/students" },
    Staffs: { path: "/staffs" },
    PhotoUploads: { path: "/photo-uploads/:productType?/:cityId?/:instId?/:classId?/:sectionId?" },
    Roles: { path: "/roles" },
    ybpStaffs: { path: "/ybp-staffs" },    
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Forbidden: { path: "/403" },
};